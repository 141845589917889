import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CensorRatingDto, FindCensorRatingDto, PageResultDto, UpdateCensorRatingDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class CensorRatingService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: FindCensorRatingDto) {
    return await this.httpService.get<PageResultDto<CensorRatingDto>>(`/admin/censor-ratings`, { limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<CensorRatingDto>(`/admin/censor-ratings/${id}`)
  }

  async setImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/censor-ratings/${id}/image`, { fileId: iconId })
  }

  async setMobileImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/censor-ratings/${id}/mobile-image`, { fileId: iconId })
  }

  async update(id: string, updateCensorRatingDto: UpdateCensorRatingDto) {
    return await this.httpService.put<Boolean>(`/admin/censor-ratings/${id}`, updateCensorRatingDto)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/onboard/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/onboard/${id}/disable`)
  }
}
