import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'
import { DragulaService } from 'ng2-dragula'
import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, PageService, UserService, ProfileService, SettingService, DashboardService, WindowRef, PromotionService, AreaCategoryService, FilmFormatService, FilmAttributeService, CinemaService, NewsService, ConcessionService, BannerOnboardService, CensorRatingService, MembershipService, ActivatedCardService, OrderService, BannerService, ChannelService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    SettingService,
    PageService,
    PromotionService,
    DashboardService,
    AreaCategoryService,
    FilmFormatService,
    FilmAttributeService,
    CinemaService,
    ConcessionService,
    BannerOnboardService,
    CensorRatingService,
    NewsService,
    MembershipService,
    ActivatedCardService,
    OrderService,
    BannerService,
    ChannelService,
    AuthStore,
    AuthQuery,
    WindowRef,
    DragulaService
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
