import { Injectable } from '@angular/core'

import { CreateMembershipBenefitDto, FindMembershipBenefitDto, MembershipBenefitDto, MembershipDto, NewsDto, PageResultDto, UpdateMembershipBenefitDto, UpdateMembershipDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class MembershipService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<MembershipDto>('/admin/membership')
  }

  async update(updateMembershipDto: UpdateMembershipDto) {
    return await this.httpService.put<Boolean>(`/admin/membership`, updateMembershipDto)
  }

  async findBenefit(findMembershipBenefitDto: FindMembershipBenefitDto) {
    return await this.httpService.get<PageResultDto<MembershipBenefitDto>>('/admin/membership/benefits', findMembershipBenefitDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<NewsDto>(`/admin/membership/benefits/${id}`)
  }

  async createBenefit(createMembershipBenefitDto: CreateMembershipBenefitDto) {
    return await this.httpService.post<NewsDto>(`/admin/membership/benefits`, createMembershipBenefitDto)
  }

  async updateBenefit(id: string, updateMembershipBenefitDto: UpdateMembershipBenefitDto) {
    return await this.httpService.put<Boolean>(`/admin/membership/benefits/${id}`, updateMembershipBenefitDto)
  }

  async deleteBenefit(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/membership/benefits/${id}`)
  }

  async setEnableBenefit(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/membership/benefits/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/membership/benefits/${id}/disable`)
  }
}
