import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { FilmAttributeDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class FilmAttributeService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<FilmAttributeDto[]>(`/admin/film-attributes`)
  }

  async findOne(id: string) {
    return await this.httpService.get<FilmAttributeDto>(`/admin/film-attributes/${id}`)
  }

  async setIcon(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/film-attributes/${id}/icon`, { fileId: iconId })
  }

  async setTag(id: string, showAsTag: boolean) {
    if (showAsTag) {
      return await this.httpService.patch<Boolean>(`/admin/film-attributes/${id}/show-tag`)
    }

    return await this.httpService.patch<Boolean>(`/admin/film-attributes/${id}/hide-tag`)
  }

  async sortOrder(attributeIds: string[]) {
    return await this.httpService.put(`/admin/film-attributes/sort-order`, { ids: attributeIds })
  }
}
