import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { BannerOnboardDto, CreateBannerOnboardDto, FindBannerOnboardDto, PageResultDto, SortOrderBannerOnboardDto, UpdateBannerOnboardDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class BannerOnboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ searchText, limit, offset }: FindBannerOnboardDto) {
    return await this.httpService.get<PageResultDto<BannerOnboardDto>>(`/admin/onboard`, { searchText, limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<BannerOnboardDto>(`/admin/onboard/${id}`)
  }

  async setImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/onboard/${id}/image`, { fileId: iconId })
  }

  async create(createBannerOnboardDto: CreateBannerOnboardDto) {
    return await this.httpService.post<BannerOnboardDto>(`/admin/onboard`, createBannerOnboardDto)
  }

  async update(id: string, updateBannerOnboardDto: UpdateBannerOnboardDto) {
    return await this.httpService.put<Boolean>(`/admin/onboard/${id}`, updateBannerOnboardDto)
  }

  async sortOrder(sortOrderBannerOnboardDto: SortOrderBannerOnboardDto) {
    return await this.httpService.put<Boolean>(`/admin/onboard/sort-order`, sortOrderBannerOnboardDto)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/onboard/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/onboard/${id}/disable`)
  }
}
