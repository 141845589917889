// import { Injectable } from '@angular/core'

// import { BannerDetailDto, BannerItemDto, CreateOrUpdateBannerDto } from '../dto'
// import { BannerDto, UpdateBannerDto } from '../dto/banner.dto'

// import { HttpService } from './http.service'

// @Injectable()
// export class BannerService {
//   constructor(
//     private httpService: HttpService) {
//   }

//   async find() {
//     return await this.httpService.get<BannerDto[]>('/admin/banners')
//   }

//   async findOneBanner(bannerId: string) {
//     return await this.httpService.get<BannerDetailDto>(`/admin/banners/${bannerId}`)
//   }

//   async updateBanner(bannerId: string, bannerItemId: string, updatePageDto: UpdateBannerDto) {
//     return await this.httpService.put<any>(`/admin/banners/${bannerId}/items/${bannerItemId}/enable`, updatePageDto)
//   }

//   async fineOneBannerItem(bannerId: string, bannerItemId: string) {
//     return await this.httpService.get<BannerItemDto>(`/admin/banners/${bannerId}/items/${bannerItemId}`)
//   }
  
//   async createItemBanner(bannerId: string, createOrUpdateBannerDto: Partial<CreateOrUpdateBannerDto>)  {
//     return await this.httpService.post<any>(`/admin/banners/${bannerId}/items`, createOrUpdateBannerDto)
//   }

//   async UpdateBannerItem(bannerId: string, bannerItemId: string, createOrUpdateBannerDto: Partial<CreateOrUpdateBannerDto>) {
//     return await this.httpService.put<any>(`/admin/banners/${bannerId}/items/${bannerItemId}`, createOrUpdateBannerDto)
//   }

//   async deleteBannerItem(bannerId: string, bannerItemId: string) {
//     return await this.httpService.delete(`/admin/banners/${bannerId}/items/${bannerItemId}`)
//   }
// }


import { Injectable } from '@angular/core'

import { BannerDetailDto, BannerItemDto, CreateOrUpdateBannerDto } from '../dto'
import { BannerDto, UpdateBannerDto } from '../dto/banner.dto'

import { HttpService } from './http.service'

@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService) {
  }

  async findOneBanner() {
    return await this.httpService.get<BannerDetailDto>(`/admin/banners`)
  }

  async fineOneBannerItem(bannerItemId: string) {
    return await this.httpService.get<BannerItemDto>(`/admin/banners/${bannerItemId}`)
  }
  
  async createItemBanner(createOrUpdateBannerDto: Partial<CreateOrUpdateBannerDto>)  {
    return await this.httpService.post<any>(`/admin/banners`, createOrUpdateBannerDto)
  }

  async UpdateBannerItem(bannerItemId: string, createOrUpdateBannerDto: Partial<CreateOrUpdateBannerDto>) {
    return await this.httpService.put<any>(`/admin/banners/${bannerItemId}`, createOrUpdateBannerDto)
  }

  async deleteBannerItem(bannerItemId: string) {
    return await this.httpService.delete(`/admin/banners/${bannerItemId}`)
  }
}
