import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { ActivatedCardDto, FindActivatedCardDto, PageResultDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class ActivatedCardService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findActivatedCardDto: FindActivatedCardDto) {
    return await this.httpService.get<PageResultDto<ActivatedCardDto>>(`/admin/activated-cards`, findActivatedCardDto)
  }

  async export({ startDate, endDate }: FindActivatedCardDto) {
    return await this.httpService.get<any>(`/admin/activated-cards/export`, { startDate, endDate })
  }

  async findOne(id: string) {
    return await this.httpService.get<ActivatedCardDto>(`/admin/activated-cards/${id}`)
  }
}
