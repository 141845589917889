import { Injectable } from '@angular/core'

import * as localforage from 'localforage'

localforage.config({
  driver      : localforage.LOCALSTORAGE,
  name        : 'MMS',
  version     : 1.0,
  storeName   : 'mms'
})

@Injectable()
export class LocalStorageService {
  constructor() {
    
  }

  // ===============================================================================================
  // Helpers
  // ===============================================================================================

  async set<T>(key: string, value: any) {
    return localforage.setItem<T>(key, value)
  }

  async get<T>(key: string) {
    return localforage.getItem<T>(key)
  }

  async remove(key: string) {
    return localforage.removeItem(key)
  }
}