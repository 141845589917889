import { Injectable } from '@angular/core'

import { CreatePromotionDto, FindPromotionDto, PageResultDto, PromotionDto, UpdatePromotionDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class PromotionService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPromotionDto: FindPromotionDto) {
    return await this.httpService.get<PageResultDto<PromotionDto>>('/admin/promotions', findPromotionDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<PromotionDto>(`/admin/promotions/${id}`)
  }

  async create(createPromotionDto: CreatePromotionDto) {
    return await this.httpService.post<PromotionDto>(`/admin/promotions`, createPromotionDto)
  }

  async update(id: string, updatePromotionDto: UpdatePromotionDto) {
    return await this.httpService.put<Boolean>(`/admin/promotions/${id}`, updatePromotionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/promotions/${id}`)
  }

  async publish(id: string) {
    return await this.httpService.post<Boolean>(`/admin/promotions/${id}/notification`)
  }

  async setThumbnail(id: string, fileId: string) {
    return await this.httpService.put<Boolean>(`/admin/promotions/${id}/thumbnail`, { fileId })
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/promotions/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/promotions/${id}/disable`)
  }
}
