import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CinemaDto, FindCinemaDto, PageResultDto, UpdateCinemaDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class CinemaService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ searchText, limit, offset }: FindCinemaDto) {
    return await this.httpService.get<PageResultDto<CinemaDto>>(`/admin/cinemas`, { searchText, limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<CinemaDto>(`/admin/cinemas/${id}`)
  }

  async setImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/cinemas/${id}/image`, { fileId: iconId })
  }

  async setMobileImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/cinemas/${id}/mobile-image`, { fileId: iconId })
  }

  async setBannerImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/cinemas/${id}/banner-image`, { fileId: iconId })
  }

  async setMobileBannerImage(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/cinemas/${id}/mobile-banner-image`, { fileId: iconId })
  }

  async update(id: string, updateCinemaDto: UpdateCinemaDto) {
    return await this.httpService.put<Boolean>(`/admin/cinemas/${id}`, updateCinemaDto)
  }
}
