import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { FilmFormatDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class FilmFormatService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<FilmFormatDto[]>(`/admin/film-formats`)
  }

  async findOne(id: string) {
    return await this.httpService.get<FilmFormatDto>(`/admin/film-formats/${id}`)
  }

  async setIcon(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/film-formats/${id}/icon`, { fileId: iconId })
  }
}
