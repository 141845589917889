import { Injectable } from '@angular/core'

import { CreateNewsDto, FindNewsDto, NewsDto, PageResultDto, UpdateNewsDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class NewsService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findNewsDto: FindNewsDto) {
    return await this.httpService.get<PageResultDto<NewsDto>>('/admin/news', findNewsDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<NewsDto>(`/admin/news/${id}`)
  }

  async create(createNewsDto: CreateNewsDto) {
    return await this.httpService.post<NewsDto>(`/admin/news`, createNewsDto)
  }

  async update(id: string, updateNewsDto: UpdateNewsDto) {
    return await this.httpService.put<Boolean>(`/admin/news/${id}`, updateNewsDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/news/${id}`)
  }

  async publish(id: string) {
    return await this.httpService.post<Boolean>(`/admin/news/${id}/notification`)
  }

  async setThumbnail(id: string, fileId: string) {
    return await this.httpService.put<Boolean>(`/admin/news/${id}/thumbnail`, { fileId })
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/news/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/news/${id}/disable`)
  }
}
